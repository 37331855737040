.bathx {
    padding: 20px;
}

.bathx > p {
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    line-height: 1.5rem;
    letter-spacing: 0.025rem;
}

.bathx > h3 {
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    margin-bottom: 20px;
    
}

.bathx > h4 {
    font-family: 'Roboto', sans-serif;
    font-size: 1.3rem;
}

.bathx > ul {
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
    margin-bottom: 20px;
    margin-left: 5rem;
    margin-right: 20px;
    line-height: 1.5rem;
    letter-spacing: 0.025rem;
}

.bathx  li {
    margin-bottom: 10px;
}

@media screen and (max-width: 960px) {
    .bathx {
        padding: 20px;
    }
    
    .bathx > p {
        font-family: 'Roboto', sans-serif;
        font-size: 1.2rem;
        margin-bottom: 20px;
        margin-left: 10px;
        margin-right: 10px;
        line-height: 1.5rem;
        letter-spacing: 0.025rem;
    }
    
    .bathx > h3 {
        font-family: 'Roboto', sans-serif;
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 20px;
        
    }
    
    .bathx > h4 {
        font-family: 'Roboto', sans-serif;
        font-size: 1.3rem;
    }
    
    .bathx > ul {
        font-family: 'Roboto', sans-serif;
        font-size: 1.2rem;
        margin-bottom: 20px;
        margin-left: 10px;
        margin-right: 05px;
        line-height: 1.5rem;
        letter-spacing: 0.025rem;
    }
    
    .bathx  li {
        margin-bottom: 10px;
    }
    
}