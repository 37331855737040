.goalsList {
  padding-left: 40px;
  padding-bottom: 20px;
  padding-right: 40px;
  padding-top: 0px;
}

.goalsList ul {
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 60px;
}

.goalsList li {
  padding-bottom: 10px; 
  
}

@media screen and (max-width: 960px) {
  .goalsList {
    padding-left: 10px;
    padding-bottom: 20px;
    padding-right: 10px;
    padding-top: 0px;
  }
  
  .goalsList ul {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .goalsList li {
    padding-bottom: 20px; 
    
  }
}