.sexy-line-hero { 
  height: 2px;
  border: 0;
  margin:auto;
  margin-top: -20px;
  margin-bottom: 20px;
  background: black;
  background: -webkit-gradient(linear, 0 0, 100% 0, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0)), color-stop(50%, #ffffff));
}

.hero-container-home {
  background: url("../../public/images/deadVirus.jpg") center center/cover
    no-repeat;
  height: 95vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0px 10px 34px -21px rgba(0,0,0,1); */
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
  object-fit: contain;
}

.hero-container-home > h1 {
  color: #fff;
  font-size: 125px;
  margin-left: 0px;
  margin-top: -20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  text-shadow: 6px 6px 3px rgba(0, 0, 0, 0.4);
}

.hero-container-home > h2 {
  color: #fff;
  font-size: 48px;
  margin-left: 0px;
  margin-top: -20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  text-shadow: 4px 4px 3px rgba(0, 0, 0, 0.4);
}

.hero-container-home > h3 {
  color: #fff;
  font-size: 36px;
  margin-left: 0px;
  margin-top: 0px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
}

.hero-container-home > h4 {
  color: #fff;
  font-size: 24px;
  margin-left: 0px;
  margin-top: 0px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  text-shadow: 6px 6px 3px rgba(0, 0, 0, 0.4);
}

.hero-container-pillars {
  background: url("../../public/images/pillars.jpg") center center/cover
    no-repeat;
  height: 95vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0px 10px 34px -21px rgba(0,0,0,1); */
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.25);
  object-fit: contain;
}

.hero-container-pillars > h1 {
  color: #fff;
  font-size: 80px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  text-align: center;
  width: 1000px;
  margin-left: 0px;
  margin-top: 0px;
  padding: 0px;
  text-shadow: 6px 6px 3px rgba(0, 0, 0, 0.4);
}

.hero-container-pillars .sexy-line-hero {
  margin-top: 0px;
}

.hero-container-about {
  background: url("../../public/images/about.jpg") center center/cover no-repeat;
  height: 95vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0px 10px 34px -21px rgba(0,0,0,1); */
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.25);
  object-fit: contain;
}

.hero-container-about > h1 {
  color: #fff;
  font-size: 125px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  text-align: center;
  width: 1000px;
  margin-left: 0px;
  margin-top: 0px;
  padding: 0px;
  text-shadow: 6px 6px 3px rgba(0, 0, 0, 0.4);
}

.hero-container-contact {
  background: url("../../public/images/contact.jpg") center center/cover
    no-repeat;
  height: 95vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0px 10px 34px -21px rgba(0,0,0,1); */
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.25);
  object-fit: contain;
}

.hero-container-contact > h1 {
  color: #fff;
  font-size: 125px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  text-align: center;
  width: 1000px;
  margin-left: -0px;
  margin-top: 0px;
  padding: 75px;
  text-shadow: 6px 6px 3px rgba(0, 0, 0, 0.4);
}

.hero-container-bathx {
  background: url("../../public/images/bathroom.jpg") center center/cover
    no-repeat;
  height: 95vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0px 10px 34px -21px rgba(0,0,0,1); */
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.25);
  object-fit: contain;
}

.hero-container-bathx > h1 {
  color: #fff;
  font-size: 125px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  text-shadow: 6px 6px 4px rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 1250px) {
  .hero-container-home > h1 {
    color: #fff;
    font-size: 115px;
    margin-left: 0px;
    margin-top: -20px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-shadow: 6px 6px 3px rgba(0, 0, 0, 0.4);
  }
  
  .hero-container-home > h2 {
    color: #fff;
    font-size: 40px;
    margin-left: 0px;
    margin-top: -20px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-shadow: 4px 4px 3px rgba(0, 0, 0, 0.4);
  }
  
  .hero-container-home > h3 {
    color: #fff;
    font-size: 30px;
    margin-left: 0px;
    margin-top: 0px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
  }
  
  .hero-container-home > h4 {
    color: #fff;
    font-size: 20px;
    margin-left: 0px;
    margin-top: 0px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-shadow: 6px 6px 3px rgba(0, 0, 0, 0.4);
  }
  .hero-container-pillars > h1 {
    color: #fff;
    font-size: 60px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-align: center;
    width: 400px;
    margin-left: 0px;
    margin-top: 0px;
    padding: 0px;
    text-shadow: 6px 6px 3px rgba(0, 0, 0, 0.4);
  }
  .hero-container-about > h1 {
    color: #fff;
    font-size: 100px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-align: center;
    width: 1000px;
    margin-left: 0px;
    margin-top: 0px;
    padding: 0px;
    text-shadow: 6px 6px 3px rgba(0, 0, 0, 0.4);
  }
}

@media screen and (max-width: 1000px) {
  
  .hero-container-home {
    background: url("../../public/images/deadVirus.jpg") center center/cover
      no-repeat;
    background-position: 75%;
    height: 95vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* box-shadow: 0px 10px 34px -21px rgba(0,0,0,1); */
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
    object-fit: contain;
    
  }
  .hero-container-about > h1 {
    color: #fff;
    font-size: 75px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-align: center;
    width: 1000px;
    margin-left: 0px;
    margin-top: 0px;
    padding: 0px;
    text-shadow: 6px 6px 3px rgba(0, 0, 0, 0.4);
  }


  .hero-container-home > h1 {
    color: #fff;
    font-size: 75px;
    margin-left: 0px;
    margin-top: -20px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-shadow: 6px 6px 3px rgba(0, 0, 0, 0.4);
  }

  .hero-container-home > h2 {
    color: #fff;
    font-size: 30px;
    margin-left: 0px;
    margin-top: -20px;
    font-family: "Montserrat", sans-serif;
    text-align: center;
    font-weight: 700;
    text-shadow: 4px 4px 3px rgba(0, 0, 0, 0.4);
  }

  .hero-container-home > h3 {
    color: #fff;
    font-size: 24px;
    margin-left: 0px;
    margin-top: 0px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
  }

  .hero-container-home > h4 {
    color: #fff;
    font-size: 18px;
    text-align: center;
    margin-left: 0px;
    margin-top: 0px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-shadow: 6px 6px 3px rgba(0, 0, 0, 0.4);
  }

  .hero-container-pillars {
    background: url("../../public/images/pillars.jpg") center center/cover
      no-repeat;
    background-position: 45%;
    height: 95vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* box-shadow: 0px 10px 34px -21px rgba(0,0,0,1); */
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.25);
    object-fit: contain;
  }

  .hero-container-pillars > h1 {
    color: #fff;
    font-size: 45px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-align: center;
    width: 400px;
    margin-left: 0px;
    margin-top: 0px;
    padding: 0px;
    text-shadow: 6px 6px 3px rgba(0, 0, 0, 0.4);
  }

  .hero-container-pillars .sexy-line-hero {
    margin-top: 0px;
  }

  .hero-container-about {
    background: url("../../public/images/about.jpg") center center/cover
      no-repeat;
    height: 95vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* box-shadow: 0px 10px 34px -21px rgba(0,0,0,1); */
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.25);
    object-fit: contain;
  }



  .hero-container-contact {
    background: url("../../public/images/contact.jpg") center center/cover
      no-repeat;
    background-position: 75%;
    height: 95vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* box-shadow: 0px 10px 34px -21px rgba(0,0,0,1); */
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.25);
    object-fit: contain;
  }

  .hero-container-contact > h1 {
    color: #fff;
    font-size: 60px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-align: center;
    width: 1000px;
    margin-left: -0px;
    margin-top: 0px;
    padding: 75px;
    text-shadow: 6px 6px 3px rgba(0, 0, 0, 0.4);
  }

  .hero-container-bathx {
    background: url("../../public/images/bathroom.jpg") center center/cover
      no-repeat;
    background-position: 0%;
    height: 95vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* box-shadow: 0px 10px 34px -21px rgba(0,0,0,1); */
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.25);
    object-fit: contain;
  }

  .hero-container-bathx > h1 {
    color: #fff;
    font-size: 75px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-align: center;
    padding: 0px;
    text-shadow: 6px 6px 4px rgba(0, 0, 0, 0.4);
  }
}


