

.home-section {
    padding: 20px;
}

hr{
  
    border: 0;
    color: #000;
    background-color: #DDD;
    height: 1px;
    width: 100%;
    position: relative;
    margin: 30px auto;
}

.sexy-line { 
    height: 1px;
    border: 0;
    margin:auto;
    margin-bottom: 10px;
    background: black;
    background: -webkit-gradient(linear, 0 0, 100% 0, from(white), to(white), color-stop(50%, #b2b2b2));
}

.pcb-image {
    background: url("../../../public/images/pcb.jpg") center center/cover
      no-repeat;
    height: 100%;
    width: auto;
}

.no-disinfect {
    background: url("../../../public/images/no_disinfect.jpg") center center/cover
      no-repeat;
    height: 100%;
    width: auto;
}