.pillarText > p {
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  line-height: 1.5rem;
  letter-spacing: 0.025rem;
}

.pillarText > h3 {
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
}

.pillarText > ul {
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  margin-bottom: 20px;
  margin-left: 5rem;
  margin-right: 20px;
  line-height: 1.5rem;
  letter-spacing: 0.025rem;
}

.pillarText li {
  margin-bottom: 10px;
}

@media screen and (max-width: 960px) {
  .pillarText {
    justify-content: center;
    align-items: center;
  }

  .pillarText > p {
    font-family: "Roboto", sans-serif;
    font-size: 1.1rem;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    line-height: 1.5rem;
    letter-spacing: 0.025rem;
  }

  .pillarText > h3 {
    font-family: "Roboto", sans-serif;
    font-size: 1.5rem;
  }

  .pillarText > ul {
    font-family: "Roboto", sans-serif;
    font-size: 1.2rem;

    margin-bottom: 20px;
    margin-left: -0.25rem;
    margin-right: -0.25rem;
    line-height: 1.5rem;
    letter-spacing: 0.025rem;
  }

  .pillarText li {
    margin-bottom: 10px;
  }
}
