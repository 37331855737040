* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}





.home,
.about,
.sevenPillars,
.contact {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
