.person {
    padding: 20px;
}

.person img {
    width: 175px;
    height: auto;
    float: left;
    margin: 5px;
    margin-right: 20px
}

.person p {
    margin-bottom: 45px;
    font-family: 'Roboto', sans-serif;
    font-size: 1.4rem;
    margin-left: 30px;
}

@media screen and (max-width: 960px) {
    .person {
        padding: 10px;
    }
    
    .person img {
        width: 150px;
        height: auto;
        float: left;
        margin: 5px;
        margin-right: 10px
    }
    
    .person p {
        margin-bottom: 45px;
        font-family: 'Roboto', sans-serif;
        font-size: 1.4rem;
        margin-left: 10px;
    }

}